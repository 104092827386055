import { compareDesc, parseISO } from "date-fns";
import { isEvaluator, User } from "../auth/user";
import { PartialNullable } from "../types/utility";

export interface ContributionHistory {
  id: number;
  comment: string;
  status: ContributionStatus;
  contributionId: Contribution["id"];

  createdAt: string;
}

export interface ContributionDocumentBase {
  id?: number;
  name: string;
  originalName: string;
  cover: boolean;
  description?: string | null;
  origin?: ContributionDocumentOrigin;
}

export interface ContributionDocumentToSend extends ContributionDocumentBase {}

export interface ContributionDocument extends ContributionDocumentBase {
  id: number;
  name: string;
  originalName: string;
  cover: boolean;
  description: string | null;
  origin: ContributionDocumentOrigin;
}

export enum ContributionDocumentOrigin {
  CONTRIBUTOR = 1,
  ADMIN,
}

export interface ContributionBase {
  id?: number;

  project: string;
  problem: string;
  solution: string;
  type: ContributionType;
  otherType: string | null;
  issue: ContributionIssue | null;
  factory: ContributionFactory | null;
  productType: ContributionProductType | null;

  customerScore1: number | null;
  customerScore2: number | null;
  customerScore3: number | null;
  companyScore1: number | null;
  companyScore2: number | null;
  companyScore3: number | null;

  userId: User["userId"];
  User?: User;
  UserProblemDocs?: ContributionDocumentBase[];
  UserSolutionDocs?: ContributionDocumentBase[];
  AdminProblemDocs?: ContributionDocumentBase[];
  AdminSolutionDocs?: ContributionDocumentBase[];
  Histories?: ContributionHistory[];

  draft: boolean;
}

export interface Contribution extends ContributionBase {
  id: NonNullable<ContributionBase["id"]>;

  type: NonNullable<ContributionBase["type"]>;
  UserProblemDocs: ContributionDocument[];
  UserSolutionDocs: ContributionDocument[];
  AdminProblemDocs: ContributionDocument[];
  AdminSolutionDocs: ContributionDocument[];
  Evaluations: ContributionEvaluation[];

  createdAt: string;
}

export interface ContributionToSend extends ContributionBase {
  UserProblemDocs: NonNullable<ContributionBase["UserProblemDocs"]>;
  UserSolutionDocs: NonNullable<ContributionBase["UserSolutionDocs"]>;
  AdminProblemDocs: NonNullable<ContributionBase["AdminProblemDocs"]>;
  AdminSolutionDocs: NonNullable<ContributionBase["AdminSolutionDocs"]>;
  Histories: NonNullable<ContributionBase["Histories"]>;
  status?: {
    status: ContributionStatus;
    comment: ContributionHistory["comment"];
  };
}

export type PartialContributionToSend = PartialNullable<ContributionToSend> & {
  UserProblemDocs: NonNullable<ContributionBase["UserProblemDocs"]>;
  UserSolutionDocs: NonNullable<ContributionBase["UserSolutionDocs"]>;
  AdminProblemDocs: NonNullable<ContributionBase["AdminProblemDocs"]>;
  AdminSolutionDocs: NonNullable<ContributionBase["AdminSolutionDocs"]>;
  Histories: NonNullable<ContributionBase["Histories"]>;
  status?: ContributionToSend["status"];
};

export interface ContributionScored extends CompanyScored, CustomerScored {}

/* Contribution Evaluation */
export interface ContributionEvaluationBase extends ContributionScored {
  comment: string;
}

export interface ContributionEvaluation extends ContributionEvaluationBase {
  createdAt: string;
  contributionId: Contribution["id"];
  userId: User["userId"];
  User: User;
}

export interface ContributionEvaluationToSend
  extends ContributionEvaluationBase {}

/* Statuses */
export const CONTRIBUTION_ALL_STATUSES = -1;

export enum ContributionStatus {
  DRAFT = 1,
  SUBMITTED,
  FIRST_EVALUATION,
  RETAINED_FOR_SECOND_EVALUATION,
  SECOND_EVALUATION,
  RETAINED,
  REFUSED,
}

export const CONTRIBUTION_STATUSES_ENTRIES = Object.entries(
  ContributionStatus,
).filter(([id]) => !isNaN(parseInt(id))) as [
  string,
  keyof typeof ContributionStatus,
][];

export const CONTRIBUTION_STATUSES_MAP = Object.fromEntries(
  Object.entries(ContributionStatus).map(([id, key]) => [parseInt(id), key]),
) as Record<number, keyof typeof ContributionStatus>;

/* Types */
export const CONTRIBUTION_ALL_TYPES = -1;

export enum ContributionType {
  MANUFACTURING_PROCESS = 1,
  PRODUCT,
  ACCESSORIES,
  PACKAGING,
  LOGISTICS,
  INTERNAL_PROCESS,
  CIRCULAR_ECONOMY,
  WORKPLACE_WELLNESS,
  OTHER,
  MAINTAINING,
}
export const CONTRIBUTION_TYPES_ENTRIES = [
  [`${ContributionType.MANUFACTURING_PROCESS}`, "MANUFACTURING_PROCESS"],
  [`${ContributionType.LOGISTICS}`, "LOGISTICS"],
  [`${ContributionType.MAINTAINING}`, "MAINTAINING"],
  [`${ContributionType.PACKAGING}`, "PACKAGING"],
  [`${ContributionType.INTERNAL_PROCESS}`, "INTERNAL_PROCESS"],
  [`${ContributionType.CIRCULAR_ECONOMY}`, "CIRCULAR_ECONOMY"],
  [`${ContributionType.WORKPLACE_WELLNESS}`, "WORKPLACE_WELLNESS"],
  [`${ContributionType.PRODUCT}`, "PRODUCT"],
  [`${ContributionType.ACCESSORIES}`, "ACCESSORIES"],
  [`${ContributionType.OTHER}`, "OTHER"],
] as [string, keyof typeof ContributionType][];

/* Factories */
export enum ContributionFactory {
  VISE = 1,
  ILLANGE,
  LANNEMEZAN,
  ALL,
}

/* Steps */
export enum ContributionStep {
  HOT_END = 1,
  COLD_END,
  BOTH,
}

export const CONTRIBUTION_FACTORIES_ENTRIES = Object.entries(
  ContributionFactory,
).filter(([id]) => !isNaN(parseInt(id))) as [
  string,
  keyof typeof ContributionFactory,
][];

export const CONTRIBUTION_STEPS_ENTRIES = Object.entries(
  ContributionStep,
).filter(([id]) => !isNaN(parseInt(id))) as [
  string,
  keyof typeof ContributionStep,
][];

/* ProductTypes */
export enum ContributionProductType {
  ALL = 1,
  FIBERGLASS,
  ROCKWOOL,
  BLOWN_WOOL,
}

export const CONTRIBUTION_PRODUCT_TYPE_ENTRIES = [
  [`${ContributionProductType.FIBERGLASS}`, "FIBERGLASS"],
  [`${ContributionProductType.ROCKWOOL}`, "ROCKWOOL"],
  [`${ContributionProductType.BLOWN_WOOL}`, "BLOWN_WOOL"],
  [`${ContributionProductType.ALL}`, "ALL"],
] as [string, keyof typeof ContributionProductType][];

/* Default committee filter - ALL */
export const CONTRIBUTION_ALL_COMMITTEES = -1;

/* Issues */
export enum ContributionIssue {
  SALES = 1,
  MARKETING,
  SUSTAINABLE,
}

export const CONTRIBUTION_ISSUES_ENTRIES = Object.entries(
  ContributionIssue,
).filter(([id]) => !isNaN(parseInt(id))) as [
  string,
  keyof typeof ContributionIssue,
][];

/* Getters */
export function getCurrentStatus(contribution: {
  Histories: ContributionHistory[];
  status?: {
    status: ContributionStatus;
  };
}): ContributionStatus | null {
  return contribution.status
    ? contribution.status.status
    : contribution.Histories.length > 0
    ? [...contribution.Histories].sort((a, b) =>
        compareDesc(parseISO(a.createdAt), parseISO(b.createdAt)),
      )[0].status
    : null;
}

export function isClosed(contribution: {
  Histories: ContributionHistory[];
  status?: { status: ContributionStatus } | undefined;
}): boolean {
  const currentStatus = getCurrentStatus(contribution);

  return (
    currentStatus === ContributionStatus.REFUSED ||
    currentStatus === ContributionStatus.RETAINED
  );
}

interface CustomerScored {
  customerScore1: ContributionBase["customerScore1"];
  customerScore2: ContributionBase["customerScore2"];
  customerScore3: ContributionBase["customerScore3"];
}

export function getCustomerScore(contribution: CustomerScored): number | null {
  return contribution.customerScore1 ||
    contribution.customerScore2 ||
    contribution.customerScore3
    ? (contribution.customerScore1 || 0) +
        (contribution.customerScore2 || 0) +
        (contribution.customerScore3 || 0)
    : null;
}

export function getCustomerScoreTotal(
  contribution: CustomerScored,
): number | null {
  return contribution.customerScore1 &&
    contribution.customerScore2 &&
    contribution.customerScore3
    ? contribution.customerScore1 +
        contribution.customerScore2 +
        contribution.customerScore3
    : null;
}

interface CompanyScored {
  companyScore1: ContributionBase["companyScore1"];
  companyScore2: ContributionBase["companyScore2"];
  companyScore3: ContributionBase["companyScore3"];
}

export function getCompanyScore(contribution: CompanyScored): number | null {
  return contribution.companyScore1 ||
    contribution.companyScore2 ||
    contribution.companyScore3
    ? (contribution.companyScore1 || 0) +
        (contribution.companyScore2 || 0) +
        (contribution.companyScore3 || 0)
    : null;
}

export function getCompanyScoreTotal(
  contribution: CompanyScored,
): number | null {
  return contribution.companyScore1 &&
    contribution.companyScore2 &&
    contribution.companyScore3
    ? contribution.companyScore1 +
        contribution.companyScore2 +
        contribution.companyScore3
    : null;
}

// Get sum for the scores
export function getTotalScoreSum(
  contribution: ContributionScored,
): number | null {
  const customerScore = getCustomerScore(contribution);
  if (customerScore === null) return getCompanyScore(contribution);
  const companyScore = getCompanyScore(contribution);
  if (companyScore === null) return customerScore;
  return customerScore + companyScore;
}

export function getTotalScore(contribution: ContributionScored): number | null {
  const customerScore = getCustomerScore(contribution);
  if (customerScore === null) return null;
  const companyScore = getCompanyScore(contribution);
  if (companyScore === null) return null;
  return customerScore + companyScore;
}

export function getCover(contribution: {
  UserProblemDocs: ContributionDocument[];
  UserSolutionDocs: ContributionDocument[];
}): ContributionDocument | undefined {
  return contribution.UserProblemDocs.concat(
    contribution.UserSolutionDocs,
  ).find((d) => d.cover);
}

export function getIsDraft(contribution: {
  Histories: ContributionHistory[];
}): boolean {
  return getCurrentStatus(contribution) === ContributionStatus.DRAFT;
}

export function getIsPublished(contribution: {
  Histories: ContributionHistory[];
}): boolean {
  return getCurrentStatus(contribution) !== null;
}

export function getCanUserMark(
  user: User,
  contribution: { Histories: ContributionHistory[] },
): boolean {
  return isEvaluator(user) && !getIsDraft(contribution);
}

export function getCanEdit(
  user: User,
  contribution: {
    Histories: ContributionHistory[];
    userId: ContributionBase["userId"];
  },
): boolean {
  const currentStatus = getCurrentStatus(contribution);
  return (
    user.userId === contribution.userId &&
    (currentStatus === null || currentStatus < 3)
  );
}

export function needsTypeInput(type: ContributionType): boolean {
  return type === ContributionType.OTHER;
}

export function getOwnEvaluation(
  userId: User["userId"],
  contribution: Contribution,
): ContributionEvaluation | null {
  return contribution.Evaluations.find((c) => c.userId === userId) || null;
}

const getContributionScoreAverage = <T extends keyof ContributionScored>(
  contribution: Contribution,
  scoreKey: T,
): number | null =>
  contribution.Evaluations.length > 0
    ? contribution.Evaluations.reduce(
        (average, evaluation: ContributionScored) =>
          average + ((evaluation[scoreKey] as number | null) || 0),
        0,
      ) /
      contribution.Evaluations.filter(
        (evaluation) => evaluation[scoreKey] !== null,
      ).length
    : null;

export function getContributionAverageScores(
  contribution: Contribution,
): ContributionScored {
  return {
    companyScore1: getContributionScoreAverage(contribution, "companyScore1"),
    companyScore2: getContributionScoreAverage(contribution, "companyScore2"),
    companyScore3: getContributionScoreAverage(contribution, "companyScore3"),
    customerScore1: getContributionScoreAverage(contribution, "customerScore1"),
    customerScore2: getContributionScoreAverage(contribution, "customerScore2"),
    customerScore3: getContributionScoreAverage(contribution, "customerScore3"),
  };
}

export function getIsScoreBetweenRange(
  score: number | null,
  min: number | null,
  max: number | null,
): boolean {
  if (!score) return false;
  if (min && !(min <= score)) return false;
  if (max && !(max >= score)) return false;
  return true;
}

export function filterContributionOnScoresGenerator(
  contributionsAverageScores: ContributionScored[],
  minCustomerFit: number | null,
  maxCustomerFit: number | null,
  minCompanyFit: number | null,
  maxCompanyFit: number | null,
  minGlobal: number | null,
  maxGlobal: number | null,
): (contribution: ContributionScored, index: number) => boolean {
  return (contribution, index) => {
    if (minCustomerFit || maxCustomerFit) {
      const customerScore =
        getCustomerScoreTotal(contribution) ||
        getCustomerScoreTotal(contributionsAverageScores[index]);

      if (
        !getIsScoreBetweenRange(customerScore, minCustomerFit, maxCustomerFit)
      )
        return false;
    }

    if (minCompanyFit || maxCompanyFit) {
      const companyScore =
        getCompanyScoreTotal(contribution) ||
        getCompanyScoreTotal(contributionsAverageScores[index]);

      if (!getIsScoreBetweenRange(companyScore, minCompanyFit, maxCompanyFit))
        return false;
    }

    if (minGlobal || maxGlobal) {
      const totalScore =
        getTotalScore(contribution) ||
        getTotalScore(contributionsAverageScores[index]);

      if (!getIsScoreBetweenRange(totalScore, minGlobal, maxGlobal))
        return false;
    }

    return true;
  };
}

export function getDefaultContribution(): PartialContributionToSend {
  return {
    type: null,
    factory: null,
    productType: null,
    project: "",
    problem: "",
    solution: "",
    userId: null,
    UserProblemDocs: [],
    UserSolutionDocs: [],
    AdminProblemDocs: [],
    AdminSolutionDocs: [],
    Histories: [],
    draft: false,
    otherType: null,
    issue: null,
    companyScore1: null,
    companyScore2: null,
    companyScore3: null,
    customerScore1: null,
    customerScore2: null,
    customerScore3: null,
  };
}

export function getDefaultContributionEvaluation(): ContributionEvaluationToSend {
  return {
    customerScore1: null,
    customerScore2: null,
    customerScore3: null,
    companyScore1: null,
    companyScore2: null,
    companyScore3: null,
    comment: "",
  };
}
