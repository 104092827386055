import { FunctionComponent } from "react";
import {
  PUBLIC_CONTACT_LINK,
  PUBLIC_TERMS_OF_USE_LINK,
} from "../../../routes/public";
import NavLink from "../../routing/components/NavLink";
import { useTranslation } from "react-i18next";

const PublicFooter: FunctionComponent = () => {
  const { t } = useTranslation(["auth", "ui"]);

  return (
    <div className="auth-footer">
      <a
        href="https://www.knaufinsulation.fr/"
        target="_blank"
        rel="noreferrer"
        className="link discreet-link"
      >
        {t("auth:GROUP_WEBSITE")}
      </a>

      <NavLink to={PUBLIC_TERMS_OF_USE_LINK} className="link discreet-link">
        {t("auth:TERMS_OF_USE")}
      </NavLink>
      <a
        href="https://www.knaufinsulation.fr/politique-de-confidentialité"
        rel="noreferrer"
        target="_blank"
        className="link discreet-link"
      >
        {t("auth:SECURITY_POLICY")}
      </a>
      <a
        href="https://www.knaufinsulation.com/cookie-policy"
        rel="noreferrer"
        target="_blank"
        className="link discreet-link"
      >
        {t("auth:COOKIES_POLICY")}
      </a>
      <NavLink to={PUBLIC_CONTACT_LINK} className={"link discreet-link"}>
        {t("ui:CONTACT")}
      </NavLink>
      <div
        className="copyright"
        dangerouslySetInnerHTML={{ __html: t("auth:COPYRIGHT") }}
      />
    </div>
  );
};

export default PublicFooter;
