import React, { useMemo } from "react";
import { RouteComponentProps } from "@reach/router";
import useAuth from "../../services/auth/hooks/useAuth";
import { AuthAPIConnected } from "../../services/auth/types";
import { Formik, Field, Form } from "formik";
import Link from "../../services/routing/components/Link";
import { date, number, object, string } from "yup";
import { useTranslation } from "react-i18next";
import { PRIVATE } from "../../routes/private";
import { useToasts } from "../../services/toast-notifications";
import ValidationsErrors from "src/services/validations/ValidationsErrors";
import {
  needsTypologyInput,
  USER_TYPOLOGY_ENTRIES,
} from "../../services/auth/user";
import { cx } from "@emotion/css";
import FSelectParse from "../../services/ui/elements/FSelectParse";

const Profile: React.FC<RouteComponentProps> = () => {
  const { user, updateUser, resetPassword } = useAuth() as AuthAPIConnected;
  const { error, success } = useToasts();
  const { t } = useTranslation(["auth", "ui"]);

  const ProfileSchema = useMemo(
    () =>
      object().shape({
        lastname: string().label(t("auth:LASTNAME")).required(),
        firstname: string().label(t("auth:FIRSTNAME")).required(),
        email: string().label(t("auth:EMAIL")),
        pseudo: string().nullable().label(t("auth:PSEUDO")),
        birthdate: date().nullable().label(t("auth:BIRTHDATE")),
        tel: string().nullable().label(t("auth:TEL")),
        bio: string().nullable().label(t("auth:BIO")),
        type: number()
          .label(t("auth:form.WHO_ARE_YOU"))
          .nullable()
          .required()
          .oneOf(USER_TYPOLOGY_ENTRIES.map(([id]) => parseInt(id))),
        // eslint-disable-next-line i18next/no-literal-string
        otherType: string().when("type", {
          is: needsTypologyInput,
          then: string().label(t("auth:form.SPECIFY")).nullable().required(),
          otherwise: string()
            .nullable()
            .transform(() => null),
        }),
        company: string().nullable().label(t("auth:COMPANY")),
        function: string().nullable().label(t("auth:FUNCTION")),
        country: string().nullable().label(t("auth:COUNTRY")),
      }),
    [t],
  );

  return (
    <div className="container page-content">
      <Formik
        validationSchema={ProfileSchema}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{
          otherType: "",
          ...user,
        }}
        onSubmit={(values, { setSubmitting }) => {
          updateUser(values).then(
            () => {
              setSubmitting(false);
              success(t("auth:updateUser.TOAST_SUCCESS"));
            },
            (err) => {
              setSubmitting(false);
              if (err.response.status === 412) {
                error(t("auth:updateUser.TOAST_ERROR_BAD_CURRENT_PASSWORD"));
              } else {
                error(t("auth:updateUser.TOAST_ERROR"));
              }
            },
          );
        }}
      >
        {({ isSubmitting, values }) => (
          <Form autoComplete="off">
            <div className="section">
              <h2 className="section-title">
                {t("auth:profile.PERSONAL_INFORMATIONS")}
              </h2>
              <div className="grid">
                <div className="col-md-1-2">
                  <label htmlFor="lastname" className="input-label">
                    {t("auth:LASTNAME")} *
                  </label>
                  <Field
                    type="text"
                    className="input"
                    id="lastname"
                    name="lastname"
                  />
                </div>
                <div className="col-md-1-2">
                  <label htmlFor="firstname" className="input-label">
                    {t("auth:FIRSTNAME")} *
                  </label>
                  <Field
                    type="text"
                    className="input"
                    id="firstname"
                    name="firstname"
                  />
                </div>
                <div className="col-md-1-2">
                  <label htmlFor="pseudo" className="input-label">
                    {t("auth:PSEUDO")}
                  </label>
                  <Field
                    type="text"
                    className="input"
                    id="pseudo"
                    name="pseudo"
                    value={values.pseudo || ""}
                  />
                </div>
                <div className="col-md-1-2">
                  <label htmlFor="email" className="input-label">
                    {t("auth:EMAIL")}
                  </label>
                  <Field
                    type="text"
                    className="input"
                    disabled
                    id="email"
                    name="email"
                  />
                </div>
                <div className="col-md-1-2">
                  <label htmlFor="birthdate" className="input-label">
                    {t("auth:BIRTHDATE")}
                  </label>
                  <Field
                    type="date"
                    className="input"
                    id="birthdate"
                    name="birthdate"
                    value={values.birthdate || ""}
                  />
                </div>

                <div className="col-md-1-2">
                  <label htmlFor="tel" className="input-label">
                    {t("auth:TEL")}
                  </label>
                  <Field
                    type="tel"
                    className="input"
                    id="tel"
                    name="tel"
                    value={values.tel || ""}
                  />
                </div>
                <div className="col-md-1-1">
                  <label htmlFor="bio" className="input-label">
                    {t("auth:BIO")}
                  </label>
                  <Field
                    as="textarea"
                    className="textarea"
                    cols={30}
                    rows={10}
                    id="bio"
                    name="bio"
                    value={values.bio || ""}
                  />
                </div>
              </div>
            </div>

            {!user.internal && (
              <div className="section">
                <h2 className="section-title">
                  {t("auth:profile.PROFESSIONAL_INFORMATIONS")}
                </h2>
                <div className="grid">
                  <div className="col-md-1-2">
                    <div className={"grid"}>
                      <div
                        className={cx([
                          "col",
                          "col-1-1",
                          values.type !== null &&
                            needsTypologyInput(values.type) &&
                            "col-s-1-3",
                        ])}
                      >
                        <label className="input-label">
                          {t("auth:USER_TYPOLOGY")} *
                        </label>
                        <FSelectParse
                          className={"input select"}
                          name={"type"}
                          parse={(v) =>
                            v.length !== 0 ? parseInt(v, 10) : null
                          }
                          id={"type-select"}
                        >
                          <option value="">--</option>
                          {USER_TYPOLOGY_ENTRIES.map(([id, name]) => (
                            <option value={id} key={id}>
                              {t(`auth:userTypology.${name}`)}
                            </option>
                          ))}
                        </FSelectParse>
                      </div>
                      {values.type !== null && needsTypologyInput(values.type) && (
                        <div className="col col-1-1 col-s-2-3">
                          <label
                            className="input-label"
                            htmlFor={"othertype-select"}
                          >
                            {t("auth:form.SPECIFY")} *
                          </label>
                          <Field
                            type="text"
                            className="input"
                            name={"otherType"}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-1-2">
                    <label htmlFor="company" className="input-label">
                      {t("auth:COMPANY")}
                    </label>
                    <Field
                      type="text"
                      className="input"
                      id="company"
                      name="company"
                      value={values.company || ""}
                    />
                  </div>
                  <div className="col-md-1-2">
                    <label htmlFor="function" className="input-label">
                      {t("auth:FUNCTION")}
                    </label>
                    <Field
                      type="text"
                      className="input"
                      id="function"
                      name="function"
                      value={values.function || ""}
                    />
                  </div>
                  <div className="col-md-1-2">
                    <label htmlFor="country" className="input-label">
                      {t("auth:COUNTRY")}
                    </label>
                    <Field
                      type="text"
                      className="input"
                      id="country"
                      name="country"
                      value={values.country || ""}
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="section">
              <h2 className="section-title">
                {t("auth:profile.NOTIFICATIONS")}
              </h2>
              <div className="grid">
                <div>
                  <Field
                    type="checkbox"
                    id="mailNotifications"
                    name="mailNotifications"
                  />
                  <label htmlFor="mailNotifications" className="input-label">
                    {t("auth:profile.RECEIVE_MAIL_WHEN_STATUS_CHANGE")}
                  </label>
                </div>
              </div>
            </div>

            <div className="section">
              <h2 className="section-title">{t("auth:PASSWORD")}</h2>
              <div>
                <p className="info info-discreet">
                  {t("auth:profile.FILL_FIELDS_TO_CHANGE_PASSWORD")}
                </p>
                <button
                  type={"button"}
                  className={"btn-2"}
                  onClick={() => {
                    resetPassword().then(
                      () => {
                        success(t("auth:resetPassword.SUCCESS"));
                      },
                      () => {
                        error(t("auth:resetPassword.ERROR"));
                      },
                    );
                  }}
                >
                  {t("auth:profile.RESET_PASSWORD")}
                </button>
              </div>
            </div>

            <ValidationsErrors />

            <div className="form-footer btns-bar">
              <Link to={PRIVATE} className="btn-outlined">
                {t("ui:GO_BACK")}
              </Link>
              <button type="submit" className="btn-1" disabled={isSubmitting}>
                {t("ui:UPDATE")}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Profile;
