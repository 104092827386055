import React, { FunctionComponent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { boolean, number, object, ref, SchemaOf, string } from "yup";
import { PUBLIC_COMPLETE_PROFILE_LINK } from "../../routes/public";
import { Field, Form, Formik } from "formik";
import TermsOfUseDialog from "./TermsOfUseDialog";
import ReCAPTCHA from "react-google-recaptcha";
import { gRecaptchaClientKey } from "./config";
import {
  needsTypologyInput,
  RegisterFormUserInfos,
  USER_TYPOLOGY_ENTRIES,
} from "./user";
import ValidationsErrors from "../validations/ValidationsErrors";
import FSelectParse from "../ui/elements/FSelectParse";
import { cx } from "@emotion/css";
import { AxiosError } from "axios";
import LoginButton from "./LoginButton";
import { useToasts } from "../toast-notifications";
import useAuth from "./hooks/useAuth";
import { useNavigate } from "@reach/router";

interface Props {
  onSubmit: (
    values: RegisterFormUserInfos,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => void;
  onCancel?: () => void;
  needCaptcha?: boolean;
}

const RegisterForm: FunctionComponent<Props> = ({
  onSubmit,
  onCancel,
  needCaptcha,
}) => {
  /* Hooks */
  const { t } = useTranslation(["auth", "ui"]);
  const { error } = useToasts();
  const { login } = useAuth();
  const navigate = useNavigate();

  /* Models */
  const RegisterSchema: SchemaOf<RegisterFormUserInfos> = useMemo(
    () =>
      object()
        .shape({
          email: string().label(t("auth:EMAIL")).required().email(),
          password: string()
            .label(t("auth:PASSWORD"))
            .required()
            .matches(
              /^(?=.*[A-Za-z])(?=.*\d)(?=.*[[\]€'^"(\-_).,:;{}<>=|~@$!%+*#?&/])[A-Za-z\d[\]€'^"(\-_).,:;{}<>=|~@$!%+*#?&/]{8,}$/,
              {
                message: t("auth:PASSWORD_MUST_MATCH"),
              },
            ),
          passwordConfirmation: string()
            .label(t("auth:register.PASSWORD_CONFIRMATION"))
            .required()
            .equals([ref("password")], t("auth:PASSWORDS_NOT_EQUALS")),
          lastname: string().label(t("auth:LASTNAME")).required(),
          firstname: string().label(t("auth:FIRSTNAME")).required(),
          type: number()
            .label(t("auth:form.WHO_ARE_YOU"))
            .nullable()
            .required()
            .oneOf(USER_TYPOLOGY_ENTRIES.map(([id]) => parseInt(id))),
          otherType: string().when("type", {
            is: needsTypologyInput,
            then: string().label(t("auth:form.SPECIFY")).nullable().required(),
            otherwise: string()
              .nullable()
              .transform(() => null),
          }),
          termsOfUse: boolean()
            .label(t("auth:TERMS_OF_USE"))
            .required()
            .oneOf([true]),
          securityPolicy: boolean()
            .label(t("auth:SECURITY_POLICY"))
            .required()
            .oneOf([true]),
        })
        .defined(),
    [t],
  );

  /* State */
  const [termsOfUseDialogOpen, setTermsOfUseDialogOpen] = useState(false);

  return (
    <Formik
      validationSchema={RegisterSchema}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        email: "",
        password: "",
        passwordConfirmation: "",
        termsOfUse: false,
        securityPolicy: false,
        lastname: "",
        firstname: "",
        tel: "",
        type: null,
        otherType: null,
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(RegisterSchema.validateSync(values), setSubmitting);
      }}
    >
      {({ handleChange, errors, isSubmitting, values, setFieldValue }) => (
        <Form autoComplete="off" className="auth-form" noValidate>
          <label htmlFor="" className="input-label">
            {t("auth:EMAIL")} *
          </label>
          <input
            className="input"
            name={"email"}
            onChange={handleChange}
            placeholder={t("auth:EMAIL_PLACEHOLDER")}
          />

          <div className="grid input-block">
            <div className="col">
              <label htmlFor="" className="input-label">
                {t("auth:LASTNAME")} *
              </label>
              <input
                className="input"
                type="text"
                name="lastname"
                onChange={handleChange}
                placeholder={t("auth:LASTNAME_PLACEHOLDER")}
              />
            </div>
            <div className="col">
              <label htmlFor="" className="input-label">
                {t("auth:FIRSTNAME")} *
              </label>
              <input
                className="input"
                type="text"
                name="firstname"
                onChange={handleChange}
                placeholder={t("auth:FIRSTNAME_PLACEHOLDER")}
              />
            </div>
          </div>

          <label htmlFor="tel" className="input-label">
            {t("auth:TEL")}
          </label>
          <input
            className="input"
            type="text"
            name="tel"
            onChange={handleChange}
            placeholder=""
          />

          <div className={"grid input-block"}>
            <div
              className={cx([
                "col",
                "col-1-1",
                values.type !== null &&
                  needsTypologyInput(values.type) &&
                  "col-s-1-3",
              ])}
            >
              <label className={"input-label"}>
                {t("auth:form.WHO_ARE_YOU")} *
              </label>
              <FSelectParse
                className={"input select"}
                name={"type"}
                parse={(v) => (v.length !== 0 ? parseInt(v, 10) : null)}
                id={"type-select"}
              >
                <option value="">--</option>
                {USER_TYPOLOGY_ENTRIES.map(([id, name]) => (
                  <option value={id} key={id}>
                    {t(`auth:userTypology.${name}`)}
                  </option>
                ))}
              </FSelectParse>
            </div>
            {values.type !== null && needsTypologyInput(values.type) && (
              <div className="col col-1-1 col-s-2-3">
                <label className="input-label" htmlFor={"othertype-select"}>
                  {t("auth:form.SPECIFY")} *
                </label>
                <Field type="text" className="input" name={"otherType"} />
              </div>
            )}
          </div>

          <div className={"input-block"}>
            <label htmlFor="" className="input-label">
              {t("auth:PASSWORD")} *
            </label>
            <input
              className="input"
              type="password"
              name="password"
              onChange={handleChange}
              placeholder="**********"
            />
            <p className="input-tip">{t("auth:PASSWORD_MUST_MATCH")}</p>
          </div>

          <label htmlFor="" className="input-label">
            {t("auth:register.PASSWORD_CONFIRMATION")} *
          </label>
          <input
            className="input"
            type="password"
            name="passwordConfirmation"
            onChange={handleChange}
            placeholder="**********"
          />

          <div className="input-block">
            <div>
              <input
                id="termsOfUse"
                checked={values.termsOfUse}
                type="checkbox"
                readOnly
                onClick={(event) => {
                  event.preventDefault();
                  setTermsOfUseDialogOpen(true);
                }}
              />
              <label htmlFor="termsOfUse" className="input-label label-inline">
                {t("auth:register.ACCEPT_TERMS_OF_USE")}
              </label>
            </div>

            <div>
              <input
                id="securityPolicy"
                checked={values.securityPolicy}
                onChange={handleChange}
                type="checkbox"
              />
              <a
                href="https://www.knaufinsulation.fr/politique-de-confidentialité"
                rel="noreferrer"
                className="input-label link"
                target="_blank"
              >
                {t("auth:register.ACCEPT_SECURITY_POLICY")}
              </a>
            </div>
          </div>

          <ValidationsErrors />

          {needCaptcha && (
            <ReCAPTCHA
              sitekey={gRecaptchaClientKey}
              onChange={(value: string) =>
                setFieldValue("gRecaptchaToken", value)
              }
            />
          )}

          <div
            className={
              onCancel
                ? "btns-bar form-footer"
                : "btns-bar form-footer vertical"
            }
          >
            {onCancel && (
              <button className="btn-2" onClick={onCancel}>
                {t("ui:CANCEL")}
              </button>
            )}
            <button className="btn-1" type="submit">
              {t("auth:REGISTER")}
            </button>
            {!onCancel && (
              <div>
                <LoginButton
                  className={"link"}
                  onClick={(auth0AccessToken) =>
                    login({ auth0AccessToken }).catch((err: AxiosError) => {
                      switch (err.response?.status) {
                        case 404:
                          error(t("auth:login.CREDENTIAL_ERROR"));
                          break;
                        case 412:
                          error(t("auth:login.ACCOUNT_NOT_ACTIVATED"));
                          break;
                        case 428:
                          navigate(PUBLIC_COMPLETE_PROFILE_LINK);
                          break;
                        default:
                          error(t("auth:login.AUTH_ERROR"));
                      }
                    })
                  }
                />
              </div>
            )}
          </div>
          {termsOfUseDialogOpen && (
            <TermsOfUseDialog
              initialTermsOfUse={values.termsOfUse}
              onSubmit={(termsOfUse) => {
                setFieldValue("termsOfUse", termsOfUse);
                setTermsOfUseDialogOpen(false);
              }}
              onClose={() => setTermsOfUseDialogOpen(false)}
            />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default RegisterForm;
