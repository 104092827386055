import React, { FunctionComponent, useMemo, useRef, useState } from "react";
import { cx } from "@emotion/css";
import {
  getFullName,
  isAdmin as getIsAdmin,
  isEvaluator as getIsEvaluator,
  isInternal,
  User,
} from "../auth/user";
import {
  CONTRIBUTION_FACTORIES_ENTRIES,
  CONTRIBUTION_STEPS_ENTRIES,
  CONTRIBUTION_PRODUCT_TYPE_ENTRIES,
  CONTRIBUTION_TYPES_ENTRIES,
  ContributionHistory,
  ContributionStatus,
  ContributionToSend,
  ContributionType,
  getCanEdit,
  getCompanyScore,
  getCurrentStatus,
  getCustomerScore,
  getIsDraft,
  getIsPublished,
  getTotalScoreSum,
  needsTypeInput,
  PartialContributionToSend,
} from "./contribution";
import { useTranslation } from "react-i18next";
import { array, number, object, SchemaOf, string } from "yup";
import { Field, Form, Formik } from "formik";
import FSelectParse from "../ui/elements/FSelectParse";
import ValidationsErrors from "../validations/ValidationsErrors";
import { ContributionDocumentSchema } from "./ContributionDocument";
import ContributionFormFileUpload from "./ContributionFormFileUpload";
import UserInfosDialog from "../auth/UserInfosDialog";
import Dialog from "../ui/elements/Dialog";
import useProvideContribution from "../../services/contributions/useProvideContribution";
import { useToasts } from "../../services/toast-notifications";
import { useNavigate } from "@reach/router";
import { CONTRIBUTIONS_LINK } from "../../routes/private";
import IconTrash from "../icons/IconTrash";
import IconScore from "../icons/IconScore";

interface Props {
  user: User | null;
  contribution: PartialContributionToSend;
  onSubmit: (values: ContributionToSend) => void;
  onCancel?: (isDirty: boolean) => void;
  onDirtyChange?: (isDirty: boolean) => void;
}

const ContributionForm: FunctionComponent<Props> = ({
  user,
  contribution,
  onSubmit,
  onCancel,
  onDirtyChange,
}) => {
  /* APIs */
  const { t } = useTranslation(["contributions", "ui"]);
  const { success, error } = useToasts();
  const navigate = useNavigate();

  const ContributionSchema: SchemaOf<ContributionToSend> = useMemo(
    () =>
      object()
        .shape({
          project: string().label(t("contributions:form.TITLE")).required(),
          type: number()
            .label(t("contributions:TYPE"))
            .nullable()
            .required()
            .oneOf(CONTRIBUTION_TYPES_ENTRIES.map(([id]) => parseInt(id))),
          otherType: string().when("type", {
            is: needsTypeInput,
            then: string(),
            otherwise: string()
              .nullable()
              .transform(() => null),
          }),
          factory: number()
            .label(t("contributions:FACTORY"))
            .nullable()
            .oneOf(CONTRIBUTION_FACTORIES_ENTRIES.map(([id]) => parseInt(id))),
          productType: number().when("type", {
            is: (type: ContributionType) => type === ContributionType.PRODUCT,
            then: number()
              .label(t("contributions:PRODUCT_TYPE"))
              .nullable()
              .required()
              .oneOf(
                CONTRIBUTION_PRODUCT_TYPE_ENTRIES.map(([id]) => parseInt(id)),
              ),
            otherwise: number()
              .nullable()
              .transform(() => null),
          }),
          problem: string().label(t("contributions:PROBLEM")).required(),
          solution: string().label(t("contributions:SOLUTION")).required(),
          customerScore: number().nullable(),
          UserProblemDocs: array().defined().of(ContributionDocumentSchema),
          UserSolutionDocs: array().defined().of(ContributionDocumentSchema),
          AdminProblemDocs: array().defined().of(ContributionDocumentSchema),
          AdminSolutionDocs: array().defined().of(ContributionDocumentSchema),
        })
        .defined(),
    [t],
  );

  /* State */
  const [userInfosDialogOpen, setUserInfosDialogOpen] = useState(false);
  const [
    deleteContributionDialogOpen,
    setDeleteContributionDialogOpen,
  ] = useState(false);
  const { deleteContribution } = useProvideContribution();

  /* Methods */
  const deleteThisContribution = () => {
    return deleteContribution(contribution.id as number).then(
      () => {
        success(t("contributions:DELETE_CONTRIBUTION.SUCCESS"));
        return navigate(CONTRIBUTIONS_LINK);
      },
      (err) => {
        error(t("contributions:DELETE_CONTRIBUTION.ERROR"));
      },
    );
  };

  /* Getters */
  const currentStatus = getCurrentStatus(contribution);
  const isConnected = !!user;
  const canSaveDraft =
    !!user && (getIsDraft(contribution) || !getIsPublished(contribution));
  const isAdmin = !!user && getIsAdmin(user);
  const isOwnContribution = !user || user.userId === contribution.userId;
  const canEdit =
    !user ||
    (!!contribution.userId &&
      getCanEdit(
        user,
        contribution as { Histories: ContributionHistory[]; userId: number },
      ));
  const isContributionEvaluated =
    currentStatus === ContributionStatus.RETAINED_FOR_SECOND_EVALUATION ||
    currentStatus === ContributionStatus.SECOND_EVALUATION ||
    currentStatus === ContributionStatus.RETAINED ||
    currentStatus === ContributionStatus.REFUSED;
  const canSeeFinalMark =
    isContributionEvaluated || (isOwnContribution && !canEdit);
  const canSeeContributor =
    !!user &&
    user.userId !== contribution.userId &&
    (getIsAdmin(user) ||
      getIsEvaluator(user) ||
      (contribution.User && isInternal(contribution.User)));

  /* Refs */
  const draft = useRef(canSaveDraft);

  useMemo(() => {
    draft.current = canSaveDraft;
  }, [canSaveDraft]);

  return (
    <Formik
      validationSchema={ContributionSchema}
      initialValues={contribution}
      validateOnChange={false}
      validateOnBlur={false}
      validateOnMount={false}
      enableReinitialize={true}
      onSubmit={(values) => {
        onSubmit({
          ...ContributionSchema.validateSync(values),
          draft: draft.current,
        });
        draft.current = canSaveDraft;
      }}
    >
      {({ values, errors, setFieldValue, submitForm, dirty }) => {
        const customerScore = getCustomerScore(values);
        const companyScore = getCompanyScore(values);
        const totalScore = getTotalScoreSum(values);
        const currentStatus = getCurrentStatus(values);

        if (onDirtyChange) {
          onDirtyChange(dirty);
        }

        return (
          <Form>
            <div className="grid">
              <div
                className={cx([
                  "contribution-content-section",
                  canSeeFinalMark ? "col-md-2-3" : "col-1-1",
                ])}
              >
                <div className="grid">
                  <div className={"col-1-1"}>
                    {canSeeContributor && (
                      <>
                        <label className="input-label">
                          {t("contributions:form.CONTRIBUTOR")}
                        </label>
                        <div>
                          {isAdmin ? (
                            <button
                              className="link"
                              type="button"
                              onClick={() => setUserInfosDialogOpen(true)}
                            >
                              <span className="body-s">
                                {getFullName(contribution.User as User)}
                              </span>
                            </button>
                          ) : (
                            <span className="body-s">
                              {getFullName(contribution.User as User)}
                            </span>
                          )}
                        </div>
                      </>
                    )}
                    {userInfosDialogOpen && (
                      <UserInfosDialog
                        user={contribution.User as User}
                        onClose={() => setUserInfosDialogOpen(false)}
                        canSeeAllFields={isAdmin}
                        mailSubject={`${t("contributions:form.MAIL_OBJECT")} '${
                          contribution.project
                        }'`}
                      />
                    )}
                    <fieldset disabled={!canEdit}>
                      <div className="input-block">
                        <label
                          className="input-label"
                          htmlFor={"project-input"}
                        >
                          {t("contributions:form.CONTRIBUTION_TITLE")} *
                        </label>
                        <Field
                          className={"input"}
                          name={"project"}
                          id={"project-input"}
                        />
                      </div>

                      <div className="grid input-block">
                        <div className="col col-1-1">
                          <label
                            className="input-label"
                            htmlFor={"type-select"}
                          >
                            {t("contributions:FACTORY")}
                          </label>
                          <FSelectParse
                            className={"select"}
                            name={"factory"}
                            parse={(val) => parseInt(val) || null}
                            id={"type-select"}
                          >
                            <option value="">--</option>
                            {CONTRIBUTION_FACTORIES_ENTRIES.map(
                              ([id, name]) => (
                                <option value={id} key={id}>
                                  {t(`contributions:factory.${name}`)}
                                </option>
                              ),
                            )}
                          </FSelectParse>
                        </div>
                      </div>

                      <div className="grid input-block">
                        <div className="col">
                          <label
                            className="input-label"
                            htmlFor={"type-select"}
                          >
                            {t("contributions:TYPE")} *
                          </label>
                          <FSelectParse
                            className={"select"}
                            name={"type"}
                            parse={(val) => parseInt(val) || null}
                            id={"type-select"}
                          >
                            <option value="">--</option>
                            {CONTRIBUTION_TYPES_ENTRIES.filter(
                              ([id]) => parseInt(id) !== ContributionType.OTHER,
                            ).map(([id, name]) => (
                              <option value={id} key={id}>
                                {t(`contributions:type.${name}`)}
                              </option>
                            ))}
                            <option value={String(ContributionType.OTHER)}>
                              {t("contributions:type.OTHER")}
                            </option>
                          </FSelectParse>
                        </div>
                        {values.type && needsTypeInput(values.type) && (
                          <div className="col">
                            <label
                              className="input-label"
                              htmlFor={"othertype-select"}
                            >
                              {t("contributions:form.SPECIFY")}
                            </label>
                            <Field
                              type="text"
                              className="input"
                              name={"otherType"}
                              value={values.otherType || ""}
                              id={"othertype-select"}
                            />
                          </div>
                        )}
                      </div>

                      {values.type ===
                        ContributionType.MANUFACTURING_PROCESS && (
                        <div className="grid input-block">
                          <div className="col col-1-1">
                            <label
                              className="input-label"
                              htmlFor={"type-select"}
                            >
                              {t("contributions:STEP")}
                            </label>
                            <FSelectParse
                              className={"select"}
                              name={"step"}
                              parse={(val) => parseInt(val) || null}
                              id={"type-select"}
                            >
                              <option value="">--</option>
                              {CONTRIBUTION_STEPS_ENTRIES.map(([id, name]) => (
                                <option value={id} key={id}>
                                  {t(`contributions:step.${name}`)}
                                </option>
                              ))}
                            </FSelectParse>
                          </div>
                        </div>
                      )}

                      {values.type === ContributionType.PRODUCT && (
                        <div className="grid input-block">
                          <div className="col col-1-1">
                            <label
                              className="input-label"
                              htmlFor={"type-select"}
                            >
                              {t("contributions:PRODUCT_TYPE")} *
                            </label>
                            <FSelectParse
                              className={"select"}
                              name={"productType"}
                              parse={(val) => parseInt(val) || null}
                              id={"type-select"}
                            >
                              <option value="">--</option>
                              {CONTRIBUTION_PRODUCT_TYPE_ENTRIES.map(
                                ([id, name]) => (
                                  <option value={id} key={id}>
                                    {t(`contributions:productType.${name}`)}
                                  </option>
                                ),
                              )}
                            </FSelectParse>
                          </div>
                        </div>
                      )}

                      <label className="input-label" htmlFor={"problem-input"}>
                        {t("contributions:form.PROBLEM_DESCRIPTION")} *
                      </label>
                      <Field
                        as={"textarea"}
                        className="textarea"
                        cols={30}
                        rows={10}
                        name="problem"
                        id={"problem-input"}
                      />
                      {isConnected ? (
                        <ContributionFormFileUpload
                          docs={values.UserProblemDocs}
                          onChange={(d) => setFieldValue("UserProblemDocs", d)}
                          onCoverChange={(checked) => {
                            if (checked) {
                              setFieldValue(
                                "UserSolutionDocs",
                                values.UserSolutionDocs.map((doc) => ({
                                  ...doc,
                                  cover: false,
                                })),
                              );
                            }
                          }}
                          isDisabled={!canEdit}
                          label={t("contributions:form.PROBLEM_FILES")}
                        />
                      ) : (
                        <p className="input-tip">
                          {t("contributions:form.UPLOAD_FILES_WHEN_LOGGED")}
                        </p>
                      )}

                      <label className="input-label" htmlFor={"solution-input"}>
                        {t("contributions:form.CONTRIBUTION_DESCRIPTION")} *
                      </label>
                      <Field
                        as={"textarea"}
                        className="textarea"
                        cols={30}
                        rows={10}
                        name="solution"
                        id={"solution-input"}
                      />
                      {isConnected ? (
                        <ContributionFormFileUpload
                          docs={values.UserSolutionDocs}
                          onChange={(d) => setFieldValue("UserSolutionDocs", d)}
                          onCoverChange={(checked) => {
                            if (checked) {
                              setFieldValue(
                                "UserProblemDocs",
                                values.UserProblemDocs.map((doc) => ({
                                  ...doc,
                                  cover: false,
                                })),
                              );
                            }
                          }}
                          isDisabled={!canEdit}
                          label={t("contributions:form.CONTRIBUTION_FILES")}
                        />
                      ) : (
                        <p className="input-tip">
                          {t("contributions:form.UPLOAD_FILES_WHEN_LOGGED")}
                        </p>
                      )}
                    </fieldset>
                  </div>
                </div>

                {/* Handling errors */}
                <ValidationsErrors errors={errors} />

                {canEdit && (
                  <div className="form-footer btns-bar">
                    {onCancel && (
                      <button
                        onClick={() => onCancel(dirty)}
                        type={"button"}
                        className="btn-outlined"
                      >
                        {t("ui:CANCEL")}
                      </button>
                    )}
                    {canSaveDraft && (
                      <button type="submit" className="btn-2">
                        {t("ui:SAVE_DRAFT")}
                      </button>
                    )}
                    <button
                      type="button"
                      className="btn-1"
                      onClick={() => {
                        draft.current = false;
                        return submitForm();
                      }}
                    >
                      {t("ui:SUBMIT")}
                    </button>
                    {contribution.id && (
                      <button
                        className="link"
                        type="button"
                        onClick={() => setDeleteContributionDialogOpen(true)}
                      >
                        <IconTrash />
                      </button>
                    )}
                  </div>
                )}
              </div>

              {deleteContributionDialogOpen && (
                <Dialog onClose={() => setDeleteContributionDialogOpen(false)}>
                  {t(
                    "contributions:form.ARE_YOU_SURE_TO_DELETE_THIS_CONTRIBUTION",
                  )}
                  <div className="btns-bar dialog-footer">
                    <button
                      type={"button"}
                      className={"btn-outlined"}
                      onClick={() => setDeleteContributionDialogOpen(false)}
                    >
                      {t("ui:CANCEL")}
                    </button>
                    <button
                      type={"button"}
                      className={"btn-1"}
                      onClick={() => deleteThisContribution()}
                    >
                      {t("ui:CONFIRM")}
                    </button>
                  </div>
                </Dialog>
              )}

              {canSeeFinalMark && (
                <div className="col-md-1-3">
                  <div className="card admin-panel">
                    <div className="card-body">
                      {isContributionEvaluated && (
                        <>
                          <div className="section section-sep">
                            <label className="input-label">
                              {t("contributions:form.STATUS")}
                            </label>
                            <p className="status">
                              {t(
                                `contributions:status.${
                                  ContributionStatus[
                                    currentStatus as ContributionStatus
                                  ] as keyof typeof ContributionStatus
                                }`,
                              )}
                            </p>
                          </div>
                          <div className="section section-sep">
                            <label className="input-label">
                              {t("contributions:form.SCORES")}
                            </label>
                            <div className="scores">
                              <div
                                className={cx([
                                  "score score-customer",
                                  !customerScore && "nd",
                                ])}
                              >
                                <span>{customerScore || "-"}</span>
                                <p className="label">
                                  {t("contributions:commission.FIRST")}
                                </p>
                              </div>
                              <div
                                className={cx([
                                  "score score-company",
                                  !companyScore && "nd",
                                ])}
                              >
                                <span>{companyScore || "-"}</span>
                                <p className="label">
                                  {t("contributions:commission.SECOND")}
                                </p>
                              </div>
                              <div
                                className={cx([
                                  "score score-big",
                                  !totalScore && "nd",
                                ])}
                              >
                                <IconScore />
                                <span>{totalScore || "-"}</span>
                                <p className="label">
                                  {t("contributions:TOTAL_SCORE")}
                                </p>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {isOwnContribution && !canEdit && (
                        <div className="section section-sep">
                          <div className="page-footer">
                            <div className="info">
                              {t("contributions:form.SUBMITTED_MESSAGE")}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ContributionForm;
